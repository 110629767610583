import React from 'react'
import Layout from '../components/layout';
import ContentBlock from "../components/content-block";
import './contact.scss'

const TextField = ({name, label, type="text"}) => (
  <div>
    <input type="text" name={name} type={type} placeholder={label} />
  </div>
);

const TextArea = ({name, label}) => (
  <div>
    <textarea type="text" name={name} placeholder={label} />
  </div>
);

const Contact = ({data}) => {
  return (
    <Layout>
      <ContentBlock wrapper>
        <div className="contact">
          <h1>Contact</h1>
          <form data-netlify="true" name="contact" method="post">
            <TextField name="name" label="Full name"/>
            <TextField name="email" label="Email" type="email" />
            <TextField name="phone" label="Phone" />
            <TextArea name="message" label="Your Message" />
            <input className="button" type="submit" value="Submit" />
            <input type="hidden" name="form-name" value="contact" />
          </form>
        </div>
      </ContentBlock>
    </Layout>
  );
};

export default Contact;
